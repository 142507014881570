import jktresult from './jktcityrun2024/results';
import subResult from './jktcityrun2024/resultSub';

const sources = {
  jakartacityrun2024: {
    banner: 'jkt-city-run-2024.jpeg',
    list: jktresult.datas,
    subList: subResult.datas,
    categories: [
      { name: '5K', src: 'main' },
      { name: '10K', src: 'main' },
      { name: '5K-UMUM', src: 'sub' },
      { name: '5K-MASTER', src: 'sub' },
      { name: '10K-UMUM', src: 'sub' },
      { name: '10K-MASTER', src: 'sub' },
    ],
  },
};

export default sources;
