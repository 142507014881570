import React from 'react';
import classes from './style';
import { EventResults, Navbar, Page404 } from '.';
import { CssBaseline, useMediaQuery } from '@mui/material';
import { Routes, Route } from 'react-router-dom';

const App = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <div style={classes.root}>
      <CssBaseline />
      {/* <Navbar /> */}
      <main style={classes.content}>
        {/* <main style={isMobile ? classes.contentMobile : classes.content}> */}
        <div style={!isMobile ? classes.toolbar : {}} />
        <Routes>
          <Route path="*" element={<Page404 />} />
          <Route exact path="/:event" element={<EventResults />} />
        </Routes>
      </main>
    </div>
  );
};

export default App;
