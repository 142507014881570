import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Paper,
  Tabs,
  Tab,
  TextField,
  MenuItem,
  IconButton,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import sources from '../../data/sources';
import { useEffect, useState } from 'react';
import { Search } from '@mui/icons-material';
import { TablePaginationAction } from '..';
import useMediaQuery from '@mui/material/useMediaQuery';

const cellStyle = { display: 'flex', flex: 1 };

const HeaderCell = ({ children }) => {
  return (
    <Typography variant="body1" fontWeight={'600'}>
      {children}
    </Typography>
  );
};

const EventResults = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const { event } = useParams();
  const data = sources[event];

  const [tabsProp, setTabsProp] = useState({
    value: 0,
    label: data?.categories[0]?.name,
  });
  const [gender, setGender] = useState({ value: 'a', label: 'All' });
  const [selectedCategory, setSelectedCategory] = useState({
    name: '5K',
    src: 'main',
  });
  const [selectOpts, setSelectOpts] = useState([
    { value: 'a', label: 'All' },
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
  ]);

  const [searchKey, setSearchKey] = useState('');
  const [rank, setRank] = useState(0);

  const [list, setList] = useState([]);
  const [listLength, setListLength] = useState();

  //#region pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(isMobile ? 50 : 10);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listLength) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setList([]);
    setRank(0);

    const cat = data?.categories.find(
      (cat) => cat.name === selectedCategory.name
    );

    const mainOrSubList = cat.src === 'main' ? data?.list : data?.subList;

    const filteredList = mainOrSubList.filter((p) => p.category === cat.name);
    let genderList = filteredList;

    if (gender.value !== 'a')
      genderList = filteredList.filter((p) => p.gender === gender.value);

    setTimeout(() => {
      setList(
        genderList.slice(
          newPage * rowsPerPage,
          newPage * rowsPerPage + rowsPerPage
        )
      );
    }, 100);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    setSearchKey('');
    setRank(0);
    setList([]);

    const cat = data?.categories.find(
      (cat) => cat.name === selectedCategory.name
    );

    const mainOrSubList = cat.src === 'main' ? data?.list : data?.subList;

    const filteredList = mainOrSubList.filter((p) => p.category === cat.name);
    let genderList = filteredList;

    if (gender.value !== 'a')
      genderList = filteredList.filter((p) => p.gender === gender.value);

    setTimeout(() => {
      setList(
        genderList.slice(
          0 * newRowsPerPage,
          0 * newRowsPerPage + newRowsPerPage
        )
      );
    }, 100);
  };
  //#endregion

  useEffect(() => {
    if (!tabsProp) return;

    // console.log(data?.categories[tabsProp.value]?.name);
    const categoryList = data.list.filter(
      (p) => p.category === selectedCategory.name
    );

    let genderList = categoryList;
    if (gender.value !== 'a')
      genderList = categoryList.filter(
        (p) => p.gender.toLowerCase() === gender.value.toLowerCase()
      );
    setListLength(genderList.length);
    setList(
      genderList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    );
  }, []);

  const handleChange = (e, newValue) => {
    const updated = { ...tabsProp };
    let labelProp;
    let fieldProp;
    switch (newValue) {
      case 0:
        labelProp = 'Order Id';
        fieldProp = 'order_id';
        break;
      case 1:
        labelProp = 'Ticket Id';
        fieldProp = 'offlane_ticket_id';
        break;
      case 2:
        labelProp = 'Runner Name';
        fieldProp = 'name';
        break;
      case 3:
        labelProp = 'BIB Number';
        fieldProp = 'generatedBib';
        break;
      default:
        labelProp = 'QR Code';
        break;
    }
    updated.value = newValue;
    updated.label = labelProp;
    updated.field = fieldProp;
    setTabsProp(updated);

    // console.log(e.target.value);
    const gen = { ...gender };
    gen.value = 'a';
    setGender(gen);
    setRank(0);

    //
    setList([]);
    setTimeout(() => {
      const newList = [...data.list];
      const initialList = newList.filter(
        (p) => p.category === data?.categories[newValue]?.name
      );
      setListLength(initialList.length);
      setPage(0);
      setList(
        initialList.slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage)
      );
    }, 200);
  };

  const onChange = (e) => {
    // console.log(e.target.value);
    const updated = { ...gender };
    updated.value = e.target.value;
    setGender(updated);
    setSearchKey('');
    setList([]);
    setRank(0);

    const cat = data?.categories.find(
      (cat) => cat.name === selectedCategory.name
    );

    const mainOrSubList = cat.src === 'main' ? data?.list : data?.subList;

    const filteredList = mainOrSubList.filter((p) => p.category === cat.name);

    let genderList = filteredList;

    if (e.target.value !== 'a')
      genderList = filteredList.filter((p) => p.gender === e.target.value);

    setTimeout(() => {
      setListLength(genderList.length);
      setPage(0);
      setList(genderList.slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage));
    }, 100);
  };

  const onCategoryChange = (e) => {
    setSearchKey('');
    const cat = data?.categories.find((cat) => cat.name === e.target.value);

    const mainOrSubList = cat.src === 'main' ? data?.list : data?.subList;
    // console.log(mainOrSubList);

    const categoryList = mainOrSubList.filter((p) => p.category === cat.name);

    let genderList = categoryList;
    if (cat.src === 'sub') {
      setSelectOpts([
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
      ]);
      genderList = categoryList.filter(
        (p) => p.gender.toLowerCase() === 'male'
      );
      setGender({ value: 'Male', label: 'Male' });
    } else {
      setSelectOpts([
        { value: 'a', label: 'All' },
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
      ]);

      setGender({ value: 'a', label: 'All' });
      genderList = categoryList;
    }

    setList([]);

    setTimeout(() => {
      setListLength(genderList.length);
      setList(genderList.slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage));
      setSelectedCategory(cat);
    }, 100);
  };

  const handleSearchKeyChange = (e) => {
    setSearchKey(e.target.value);
  };

  const handleSearch = () => {
    // console.log(searchKey);
    setList([]);
    setPage(0);

    const cat = data?.categories.find(
      (cat) => cat.name === selectedCategory.name
    );

    const mainOrSubList = cat.src === 'main' ? data?.list : data?.subList;

    const filteredList = mainOrSubList.filter((p) => p.category === cat.name);

    let genderList = filteredList;

    if (gender.value !== 'a')
      genderList = filteredList.filter((p) => p.gender === gender.value);

    let searchList = genderList.filter((p) =>
      p?.name?.toLowerCase().includes(searchKey?.toLowerCase())
    );

    if (searchList.length === 0)
      searchList = genderList.filter((p) =>
        p?.bib?.toString().includes(searchKey)
      );

    setTimeout(() => {
      setListLength(searchList.length);
      setList(searchList);
    }, 100);
  };

  return (
    <div style={{ marginBottom: '40px' }}>
      {/* <Typography>{JSON.stringify(data)}</Typography> */}
      <Box display={'flex'} justifyContent={'center'}>
        <img
          src={require(`../../assets/banner/${data.banner}`)}
          alt={data.banner}
          style={
            isMobile
              ? { width: '100vw' }
              : {
                  width: '40vw',
                  height: '35vh',
                  objectFit: 'fill',
                  borderRadius: '20px',
                }
          }
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginLeft: '10em',
          marginRight: '10em',
          paddingTop: '2em',
          paddingBottom: '2em',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <TextField
            sx={{ width: '280px', marginRight: '4px' }}
            size="small"
            placeholder="Search By Name... / BIB..."
            value={searchKey}
            onChange={handleSearchKeyChange}
          />
          <IconButton onClick={handleSearch}>
            <Search />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          paddingRight: { xs: '10px', sm: '10em' },
          paddingLeft: { xs: '10px', sm: '10em' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,.055)',
            borderRadius: '4px',
          }}
        >
          {/* <Box sx={{ display: 'flex', flex: 1 }}>
            <Tabs
              value={tabsProp?.value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {data?.categories.map((cat, idx) => (
                <Tab label={cat.name} />
              ))}
            </Tabs>
          </Box> */}
          <TextField
            sx={{ marginRight: '10px' }}
            fullWidth
            color={'divider'}
            select
            label={'Category'}
            value={selectedCategory.name}
            onChange={onCategoryChange}
            size="small"
          >
            {data.categories?.map((opt, idx) => (
              <MenuItem key={idx} value={opt.name}>
                {opt.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            color={'divider'}
            select
            label={'Gender'}
            value={gender.value}
            onChange={onChange}
            size="small"
          >
            {selectOpts?.map((opt, idx) => (
              <MenuItem key={idx} value={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
      <Box
        display={'flex'}
        sx={{
          marginTop: '2px',
          paddingRight: { xs: '10px', sm: '10em' },
          paddingLeft: { xs: '10px', sm: '10em' },
        }}
      >
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: { xs: 0, sm: 650 } }}
            aria-label="custom pagination table"
          >
            <TableHead>
              <TableRow sx={{ display: 'flex', flexDirection: 'row' }}>
                <TableCell sx={cellStyle}>
                  <HeaderCell>Rank</HeaderCell>
                </TableCell>
                <TableCell sx={cellStyle}>
                  <HeaderCell>Name</HeaderCell>
                </TableCell>
                <TableCell sx={cellStyle}>
                  <HeaderCell>BIB</HeaderCell>
                </TableCell>
                {!isMobile && (
                  <>
                    <TableCell sx={cellStyle}>
                      <HeaderCell>Category</HeaderCell>
                    </TableCell>
                    <TableCell sx={cellStyle}>
                      <HeaderCell>Gender</HeaderCell>
                    </TableCell>
                  </>
                )}
                <TableCell sx={cellStyle}>
                  <HeaderCell>Time</HeaderCell>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((row, idx) => (
                <TableRow
                  key={row.name}
                  sx={{ display: 'flex', flexDirection: 'row' }}
                >
                  <TableCell sx={cellStyle}>
                    {searchKey === ''
                      ? rank > 0
                        ? rank
                        : page * rowsPerPage + (idx + 1)
                      : row.rank}
                  </TableCell>
                  <TableCell sx={cellStyle}>{row.name}</TableCell>
                  <TableCell sx={cellStyle}>{row.bib}</TableCell>
                  {!isMobile && (
                    <>
                      <TableCell sx={cellStyle}>{row.category}</TableCell>
                      <TableCell sx={cellStyle}>{row.gender}</TableCell>
                    </>
                  )}
                  <TableCell sx={cellStyle}>{row.time}</TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={isMobile ? [] : [10, 25, 50]}
                  colSpan={3}
                  count={listLength}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  slotProps={{
                    select: {
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationAction}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};

export default EventResults;
